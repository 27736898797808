export const VERS = "1.0.0";

export enum ROOF_TYPE {
  "Concrete Roof" = 1, "Slate Roof", "Rosemary Roof", "Flat Roof", "Ground Mounted", "In Roof", "Dekra Roof"
}

export const BOOL_ARR = [{n: 'No', v: false}, {n: 'Yes', v: true}];

export const STORAGE_SIZE = [0, 5, 7.5, 10, 15, 20];

export const LMT_PAGE: Array<number> = [10, 20, 30];

export const DEF_IMG = "assets/images/logo.png";