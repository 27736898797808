import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-terms-policy',
  templateUrl: './terms-policy.component.html',
  styleUrls: ['./terms-policy.component.scss']
})
export class TermsPolicyComponent implements OnInit {
  @Input() data!: any[];
  @Input() heading!: string;
  @Input() updatedDate!: string;

  constructor(
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit() {
    if (Array.isArray(this.data)) {
      this.data.forEach(d => {
        let item = document.getElementById(d.id);
        if (item) {
          item!.children[1].innerHTML = d.body;
        }
      });
    }
  }

  scroll(el: any) {
    this.data = this.data.map((d: any) => {
      d.id == el ? d.isAct = true : d.isAct = false;
      return d;
    });
    const item = document.getElementById(el);
    if (item) {
      item.scrollIntoView({ behavior: "smooth" });
    }
  }
}
