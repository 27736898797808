import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpansionComponent } from './expansion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [ExpansionComponent],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule
  ],
  exports: [ExpansionComponent]
})
export class ExpansionModule { }
