import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.scss']
})
export class ExpansionComponent implements OnInit {
  @Input() heading!: string;
  @Input() data!: any;

  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
  }

  routeInside(d: any) {
    if (this.data.type === 'news')
      this.route.navigateByUrl('/news/' + d.slug);
    else if (this.data.type === 'career')
      this.route.navigateByUrl('/career/' + d.id);
  }
}
