<div class="accordianDiv mb-6">
  <h1>{{heading}}</h1>
  <mat-accordion [togglePosition]="'after'">
    <mat-expansion-panel *ngFor="let d of data.data" [disabled]="'true'" (click)="routeInside(d)" class="mt-3">
      <mat-expansion-panel-header class="ps-0 mb-3">
        <mat-panel-title class="me-5">
          {{d.title}}
        </mat-panel-title>
        <mat-panel-description *ngIf="d.salary" class="mt-10">
          {{d.salary}} • {{d.location}} • {{d.workingHours}}
        </mat-panel-description>
        <mat-icon class="ms-auto">east</mat-icon>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</div>