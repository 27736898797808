import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppApi } from '../shared/services/app-api';

@Injectable()
export class NewsService {
  constructor(
    private http: HttpClient
  ) { }

  getAllNews() {
    return this.http.get(AppApi.GET_ALL_NEWS, { params: { sorting: 'creationTime' } });
  }

  getNews(url: string | null) {
    return this.http.get(AppApi.GET_NEWS(url));
  }
}
