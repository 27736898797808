import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsPolicyComponent } from './terms-policy.component';

const routes: Routes = [{ path: '', component: TermsPolicyComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TermsPolicyRoutingModule { }
