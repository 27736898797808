import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppApi } from '../shared/services/app-api';

@Injectable()
export class AuthService {
  constructor(
    private http: HttpClient
  ) { }

  signin(data: any) {
    return this.http.post(AppApi.SIGNIN, data);
  }

  token(data: any) {
    return this.http.post(AppApi.TOKEN, data);
  }

  signup(data: any) {
    return this.http.post(AppApi.SIGNUP, data);
  }

  forgotPswd(data: any) {
    return this.http.post(AppApi.FORGOT_PSWD, data);
  }

  // reset password and confirm account
  resetPswd(data: any) {
    return this.http.post(AppApi.RESET_PSWD, data);
  }

  confirmEmail(data: any) {
    return this.http.post(AppApi.CONFIRM_EMAIL, data);
  }
}
