import { AbstractControl, ValidationErrors } from "@angular/forms";

export class Validations {

  static matchPassword(field: AbstractControl): ValidationErrors | null {
    if (!field.parent || !field)
      return null;
    const password = field.parent.get('password') || field.parent.get('newPassword');
    const confirmPassword = field.parent.get('confirmPassword') || field.parent.get('confirmNewPassword');
    if (!password || !confirmPassword)
      return null;
    if (confirmPassword.value === '')
      return null;
    if (password.value === confirmPassword.value)
      return null;
    return { 'noMatch': true };
  }

  static emailValidation(field: AbstractControl): ValidationErrors | null {
    if (field.value && String(field.value).match(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/))
      return null;
    return { 'invalidEmail': true };
  }

  static passwordValidation(field: AbstractControl): ValidationErrors | null {
    // /^([^0-9]*|[^A-Z]*|[^a-z]*)$/ > only 
    if (field.value && String(field.value).match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W\_])[A-Za-z\d\W\_]*$/))
      return null;
    return { 'invalidPassword': true };
  }

  static phoneValidation(field: AbstractControl): ValidationErrors | null {
    if (field.value && String(field.value).match(/^[0-9]{8,11}$/))
      return null;
    return { 'invalidPhone': true };
  }

  static nameValidation(field: AbstractControl): ValidationErrors | null {
    if (field.value && String(field.value).match(/^[a-zA-Z_]+( [a-zA-Z_]+)*$/))
      return null;
    return { 'invalidName': true };
  }

  static urlValidation(field: AbstractControl): ValidationErrors | null {
    if (!field.value)
      return null;
    if (field.value && String(field.value).match(/^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/))
      return null;
    return { 'invalidURL': true };
  }
}
