import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TermsPolicyRoutingModule } from './terms-policy-routing.module';
import { TermsPolicyComponent } from './terms-policy.component';


@NgModule({
  declarations: [TermsPolicyComponent],
  imports: [
    CommonModule,
    TermsPolicyRoutingModule
  ],
  exports: [TermsPolicyComponent]
})
export class TermsPolicyModule { }
