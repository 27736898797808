<div class="container">
  <div class="row">
    <div class="col-md-3 d-none d-md-block">
      <div class="sticky-top pt-5">
        <div class="side-item text-secondary my-2" [ngClass]="{'active': d.isAct}" (click)="scroll(d.id)"
          *ngFor="let d of data">
          {{d.name}}
        </div>
      </div>
    </div>
    <div class="col-md-9 mt-5">
      <h2 class="mb-2 fw-bold">{{heading}}</h2>
      <small class="text-muted">Last updated: {{updatedDate}}.</small>
      <main class="mt-4">
        <div class="my-3" *ngFor="let d of data" [id]="d.id">
          <h3 class="m-0 mb-1">{{d.name}}</h3>
          <div></div>
        </div>
      </main>
    </div>
  </div>
</div>