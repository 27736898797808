import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppApi } from '../shared/services/app-api';

@Injectable()
export class CareerService {
  constructor(
    private http: HttpClient
  ) { }

  freezeFolderObj(data: any) {
    const formData: FormData = new FormData();
    for (let key in data) {
      if (data[key]) {
        if (key === 'resume' || key === 'coverLetter' && data[key] && data[key].name)
          formData.append(key, data[key], data[key].name);
        else
          formData.append(key, data[key]);
      }
    }
    return formData;
  }

  applyJob(data: any) {
    return this.http.post(AppApi.APPLY_JOB, this.freezeFolderObj(data));
  }

  getJobs(queryParams: any) {
    return this.http.get(AppApi.GET_JOBS, { params: queryParams });
  }

  getJob(id: string) {
    return this.http.get(AppApi.GET_JOB(id));
  }
}
